import React from 'react'
import Service from '../components/Service/service';
import Footer from '../components/footer/Footer';

const Services = () => {
  return (
    <div>
      <Service />
    </div>
  )
}

export default Services
